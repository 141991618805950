<template>
  <div class="container">
    <Header :active="3" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>阻止新型冠状病毒</div>
          <div>在养老社区中肆意传播</div>
        </div>
        <div class="banner-box-text-box-tips">
          针对养老机构中快速追踪病毒接触者
        </div>
        <div
          class="horizontal-line"
          :style="{ margin: pxToRem([21, 0, 36, 0]) }"
        ></div>
        <div class="banner-box-text-box-info">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1CARE通过历史轨迹追踪、密切接触定位，积极配合落实防疫措施。对感染源快速位置追踪和及时环境消杀，最大程度阻断疫情扩散传播。
        </div>
      </div>
    </div>
    <div class="section1-bg">
      <el-image
        class="section1-bg-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img2.png"
        lazy
      ></el-image>
      <div class="section1">
        <div class="title-box">
          <div class="title">我们如何克服养老机构中接触者追踪</div>
          <div class="subtitle">
            How to overcome the contact tracing in the pension agency
          </div>
          <div class="horizontal-line m-t-21"></div>
        </div>
        <div class="section1-box">
          <div class="section1-box-left">
            <div class="section1-box-left-title">
              <span>历史轨迹追踪</span>
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png" alt="" />
            </div>
            <div
              class="horizontal-line m-t-14 m-r-48"
              style="float: right"
            ></div>
            <div class="section1-box-left-info">
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;1CARE智能化数字接触者感知解决方案，专门用于阻止致命的冠状病毒在高风险、高密度地区的传播，例如养老院和学校；
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;使用1CARE接触者感知模型，工作人员可以快速识别所有因接触感染者或接触受感染者可能污染过的物品而面临风险的人群；
              </div>
            </div>
          </div>
          <div class="section1-box-right">
            <!-- <img src="@/assets" alt="" /> -->
            <el-image
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img1.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section1-box m-t-180">
          <el-image
            :style="{
              width: pxToRem(227),
              height: pxToRem(212),
              position: 'absolute',
              top: pxToRem(-10),
              right: pxToRem(-150),
            }"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img4.png"
            lazy
          ></el-image>
          <div class="section1-box-right">
            <el-image
              class="w-635 h-615"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img3.png"
              lazy
            ></el-image>
          </div>
          <div class="section1-box-left w-486 m-t-148" style="margin-right: 0">
            <div
              class="section1-box-left-title"
              style="justify-content: flex-start"
            >
              <img
                style="margin-left: 0"
                class="m-r-25"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-left.png"
                alt=""
              />
              <span>密切接触定位</span>
            </div>
            <div class="horizontal-line m-t-14 m-l-50"></div>
            <div class="section1-box-left-info">
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;使用1CARE的接触者感知模型，可以在几秒钟内识别出所有接触过疑似携带者的居民、工作人员和访客；
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;系统按密接或次密接对接触者进行不同级别分类，使工作人员能够了解暴露于高风险的接触者，防止病毒在社区内形成传播；
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;通过检测和隔离阻断构成严重威胁的病毒，有效防止病毒的社区间传播；
              </div>
            </div>
          </div>
        </div>
        <div class="section1-box">
          <div class="section1-box-left w-394 m-r-50">
            <div
              class="section1-box-left-title"
              style="justify-content: flex-start"
            >
              <span>位置追踪</span>
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png" alt="" />
            </div>
            <div class="horizontal-line m-t-14"></div>
            <div class="section1-box-left-info">
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;位置追踪不是要暴露使用人的具体地点或位置，而是为了区域追踪病毒的传播路径；病毒感染者在离开某个区域后，病毒仍会在空气和物体表面存活，1CARE位置感知技术，通过提供感染者活动轨迹信息，防止交叉感染传播；
              </div>
            </div>
          </div>
          <div class="section1-box-right">
            <!-- <img src="@/assets" alt="" /> -->
            <el-image
              class="w-753 h-696"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img5.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section1-box m-t-36">
          <div class="section1-box-left w-394 m-r-20">
            <div
              class="section1-box-left-title"
              style="justify-content: flex-start"
            >
              <span>环境消杀</span>
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-right.png" alt="" />
            </div>
            <div class="horizontal-line m-t-14"></div>
            <div class="section1-box-left-info">
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;在易受新冠病毒感染影响的养老机构中，环境消杀是至关重要的预防感染有效措施，1CARE可以提供精准的消杀路径，避免养老机构全面停业进行消毒工作，减少不必要的大量工作量和人员流动；
              </div>
            </div>
          </div>
          <div class="section1-box-right">
            <!-- <img src="@/assets" alt="" /> -->
            <el-image
              class="w-785 h-702"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img6.png"
              lazy
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    width: 1200px;
    box-sizing: border-box;
    // left: 360px;
    top: 142px;
    left: 0;
    right: 0;
    margin: 0 auto;
    &-title {
      font-size: 60px;
      color: #1f3876;
      font-family: "UI-Bold";
      span {
        color: #90c446;
      }
    }
    &-tips {
      font-family: "CN-Light";
      color: #90c446;
      font-size: 31px;
      margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      line-height: 40px;
      color: #727171;
      width: 427px;
      font-size: 21px;
    }
    &-btn {
      width: 120px;
      height: 30px;
      margin-top: 60px;
      cursor: pointer;
      transition: all 0.8s;
    }
    &-btn:hover {
      transform: scale(1.02);
    }
  }
}
.section1-bg {
  position: relative;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/tracking/section1-img7.png") no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
  padding-bottom: 130px;
  &-img {
    position: absolute;
    width: 50%;
    top: 660px;
    left: 0;
  }
}
.section1 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 105px;
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 128px;
    position: relative;
    &-left {
      width: 427px;
      margin-right: 95px;
      &-title {
        font-family: "CN-Medium";
        font-size: 32px;
        color: #1f3876;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 23px;
          height: 24px;
          margin-left: 25px;
          cursor: pointer;
        }
      }
      &-info {
        width: 100%;
        margin-top: 38px;
        font-family: "CN-Light";
        font-size: 21px;
        color: #727171;
        text-align: justify;
      }
    }
    &-right {
      flex: 1;
      .el-image {
        width: 100%;
        // height: 491px;
        transition: all 0.8s;
      }
      .el-image:hover {
        cursor: pointer;
        transform: scale(1.02);
      }
    }
  }
}
</style>
